import * as React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { Button, icons } from '@invertase/ui';
const { Twitter, GitHub, LightBulb } = icons;

import Link from '../../components/Link';
import useDarkMode from '../../hooks/useDarkMode';
import Lottie from '../../components/Lottie';

const Background = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(12, 75px);
  top: 50%;
  transform: translateY(-50%);
  left: -200px;
  right: -200px;

  .item-1 {
    grid-column: 3 / span 2;
    grid-row: 5;
  }

  .item-2 {
    grid-column: 3 / span 4;
    grid-row: 7;
  }

  .item-3 {
    grid-column: 20 / span 4;
    grid-row: 3;
  }

  .item-4 {
    grid-column: 22 / span 3;
    grid-row: 5;
  }

  .item-5 {
    grid-column: 21 / span 3;
    grid-row: 6;
  }

  .item-6 {
    grid-column: 16 / span 3;
    grid-row: 9;
  }

  .item-7 {
    grid-column: 15 / span 3;
    grid-row: 10;
  }

  .item-8 {
    grid-column: 6 / span 3;
    grid-row: 1;
  }
`;

function Hero() {
  const { toggleDarkMode } = useDarkMode();

  return (
    <section className="relative overflow-hidden h-screen">
      <Background className="absolute z-0 bottom-0">
        <div className="item-1 rounded bg-purple-100 dark:bg-purple-900" />
        <div className="item-2 rounded bg-purple-200 dark:bg-purple-600" />
        <div className="item-3 rounded bg-purple-200 dark:bg-purple-600" />
        <div className="item-4 rounded bg-purple-300 dark:bg-purple-700" />
        <div className="item-5 rounded bg-purple-100 dark:bg-purple-900" />
        <div className="item-6 rounded bg-purple-100 dark:bg-purple-900" />
        <div className="item-7 rounded bg-purple-300 dark:bg-purple-600" />
        <div className="item-8 rounded bg-purple-100 dark:bg-purple-900" />
      </Background>
      <div className="relative z-10 h-screen">
        <div className="h-full flex flex-col items-center justify-center pt-10">
          <div
            className="relative w-full"
            style={{ maxWidth: 550, zIndex: -1, marginTop: -200, marginBottom: -100, height: 370 }}
          >
            <Lottie
              elementId="badge"
              data={require('../../../resources/lottie-badge-light-data.json')}
            />
          </div>
          <div className="px-2 lg:px-0 lg:w-1/3">
            <h2 className="mt-10 mb-10 text-2xl lg:text-4xl font-thin text-center">
              A feature rich notifications library for
              <span style={{ color: '#53C1DE' }}> React Native</span>
            </h2>
          </div>
          <div className="lg:flex my-8">
            <Button
              size="lg"
              onClick={() => {
                navigate('/react-native/docs/overview');
              }}
              className="mr-2 w-full md:w-auto"
            >
              Getting Started &raquo;
            </Button>
          </div>
          <div className="flex items-center text-base font-thin text-gray-500 mb-8">
            <Link to="/react-native/docs/overview" className="mx-4 hover:underline">
              Documentation
            </Link>
            <Link to="/react-native/reference" className="mx-4 hover:underline">
              Reference
            </Link>
            <Link to="/account" className="mx-4 hover:underline">
              My Account
            </Link>
            <button
              type="button"
              className="flex items-center hover:underline focus:outline-none"
              onClick={() => toggleDarkMode()}
            >
              <LightBulb size={16} className="text-gray-900 dark:text-yellow-500" />
            </button>
          </div>
          <ul className="flex text-gray-600">
            <li>
              <Link to="https://twitter.com/notifee_app" target="_blank" className="flex mx-2">
                <Twitter size={30} className="text-blue-500 shadow hover:shadow-lg rounded-full" />
              </Link>
            </li>
            <li>
              <Link to="https://github.com/invertase/notifee" target="_blank" className="flex mx-2">
                <GitHub
                  size={30}
                  className="text-black dark:text-white shadow hover:shadow-lg rounded-full"
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Hero;
