import * as React from 'react';
import Link from '../../components/Link';

function Banner({ text, to, classNames = '' }: { text: string; to: string; classNames: string }) {
  return (
    <Link
      className={`relative z-50 h-10 px-4 ${classNames} flex items-center justify-center transition-bg shadow-lg focus:outline-none`}
      to={to}
      target="_blank"
    >
      <span className="text-center text-gray-200 text-xs">{text}</span>
    </Link>
  );
}

export default Banner;
