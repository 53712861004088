import * as React from 'react';

import Page from '../components/Page';
import Footer from '../components/Footer';

import Banner from '../templates/homepage/Banner';
import Hero from '../templates/homepage/Hero';
import Features from '../templates/homepage/Features';
import Description from '../templates/homepage/Description';

function IndexPage({ location }: { location: Location }) {
  return (
    <Page location={location}>
      <Banner
        text="📢 &nbsp; Notifee is now free and fully open-source."
        to="https://invertase.io/blog/open-sourcing-notifee"
        classNames="bg-purple-800 dark:bg-gray-800"
      />
      <Hero />
      <Features />
      <Description />
      <Footer />
    </Page>
  );
}

export default IndexPage;
