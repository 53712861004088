import * as React from 'react';
import styled from 'styled-components';

import Container from '../../components/Container';
import Android from '../../components/Icons/Android';
import IOS from '../../components/Icons/IOS';

const Background = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-template-rows: repeat(12, 75px);

  > div {
    position: relative;
    z-index: 1;
  }

  .item-1 {
    grid-column: 1 / span 4;
    grid-row: 7;
  }

  .item-2 {
    grid-column: 20 / span 4;
    grid-row: 4;
  }

  .item-3 {
    grid-column: 22 / span 3;
    grid-row: 5;
  }

  .item-4 {
    grid-column: 17 / span 3;
    grid-row: 12;
  }

  .item-5 {
    grid-column: 3 / span 3;
    grid-row: 3;
  }
`;

type FeatureItem = [string, string, '' | 'android' | 'ios'];

const list: FeatureItem[] = [
  [
    'Channel & Category Management',
    'Manage the applications channels (Android) and categories (iOS), allowing you to customise how notifications behave when they are displayed on the device.',
    '',
  ],
  [
    'Quick Actions',
    'Present & handle quick actions alongside your notification content. Actions can be handled in the background or foreground with JavaScript code!',
    '',
  ],
  [
    'Styled Notifications',
    'Notifee supports many notification styles such as Big Text, Big Picture, Inbox & Messaging on Android and attachments & custom summary text on iOS.',
    '',
  ],
  [
    'Trigger Notifications',
    'Trigger your notifications to display at certain point in the future, or set up repeating triggers to alert your users regularly!',
    '',
  ],
  [
    'Remote & Local Image Support',
    'Notifications support displaying remote images and local with support for requiring React Native image assets.',
    '',
  ],
  [
    'Notification Events',
    'Hook into events for such as actions, input, notification displayed or dismissed when your application is in the background or foreground.',
    '',
  ],
  [
    'Service Notifications',
    'Leverage React Native headless tasks to launch foreground service notifications, e.g. music playback, navigation, progress indicators and more.',
    'android',
  ],
];

function Features() {
  return (
    <section className="overflow-hidden">
      <section className="relative overflow-hidden py-24 bg-gray-900 lg:bg-transparent">
        <Background className="absolute bg-gray-900 dark:bg-gray-800 z-10 inset-0">
          <div className="item-1 rounded bg-gray-800 dark:bg-gray-900" />
          <div className="item-2 rounded bg-gray-700" />
          <div className="item-3 rounded border border-gray-700" />
          <div className="item-4 rounded bg-gray-700" />
          <div className="item-5 rounded border border-gray-700" />
        </Background>
        <div className="h-full flex flex-col items-center justify-center text-white relative z-20 px-4">
          <Container size="sm">
            <div className="text-center">
              <h3 className="text-4xl font-thin tracking-wide">
                Bring the power of Notifee to your app
              </h3>
              <p className="font-hairline text-2xl text-gray-200">
                Notifee enables developers to rapidly build rich notifications with a simple API
                interface, whilst taking care of complex problems such as scheduling, background
                tasks, device API compatibility & more.
              </p>
            </div>
          </Container>
          <Container>
            <div className="mt-12 flex flex-wrap">
              {list.map(item => (
                <div key={item[0]} className="w-full md:w-1/2 mx-auto px-4 mb-4">
                  <Feature name={item[0]} description={item[1]} type={item[2]} />
                </div>
              ))}
            </div>
          </Container>
        </div>
      </section>
    </section>
  );
}

function Feature({ name, description, type }: { name: string; description: string; type: string }) {
  return (
    <div className="flex mb-8">
      <div className="w-16 mr-2">
        <div className="w-12 h-12 flex items-center justify-center bg-green-500 rounded-full mr-4">
          {type === 'android' && <Android branding={false} size={20} />}
          {type === 'ios' && <IOS branding={false} size={20} />}
          {type === '' && (
            <span className="text-xl" dangerouslySetInnerHTML={{ __html: '&#10003;' }} />
          )}
        </div>
      </div>
      <div>
        <div className="text-lg mb-1">{name}</div>
        <div className="text-base text-gray-500">{description}</div>
      </div>
    </div>
  );
}

export default Features;
