import * as React from 'react';
import { icons } from '@invertase/ui';

import Link from '../../components/Link';
import Container from '../../components/Container';
import Lottie from '../../components/Lottie';

const { Bolt } = icons;

function Description() {
  return (
    <Container>
      <div className="lg:flex pt-40">
        <div className="lg:w-1/2">
          <h3 className="flex flex-col lg:flex-row items-center justify-center lg:justify-end text-3xl font-thin tracking-wide text-center lg:text-right md:-mr-6">
            <span className="text-purple-900 dark:text-white">
              Create engaging notifications <br /> for your users
            </span>
            <div className="mt-6 lg:mt-0 md:ml-8 flex items-center justify-center bg-green-500 w-12 h-12 rounded-full shadow-lg">
              <Bolt size={26} className="text-white" />
            </div>
          </h3>
          <div>
            <p className="mt-8 text-center lg:text-right text-gray-600 dark:text-gray-200 tracking-wide leading-loose">
              Notifee provides a powerful JavaScript API for managing and displaying local
              notifications in your React Native application for both Android & iOS. Whether you are
              building the next chat service, an e-commerce store or any app, with Notifee you can
              present rich custom notifications using the underlying native Android & iOS APIs.
            </p>
            <div className="flex items-center justify-center lg:justify-end border-t mt-8 pt-8 mb-8">
              <Link
                to="/react-native/docs/overview"
                className="text-purple-800 hover:text-purple-900 dark:text-white dark-hover:text-gray-400 text-lg font-thin"
              >
                View Documentation &raquo;
              </Link>
            </div>
          </div>
        </div>
        <div className="relative hidden lg:block lg:w-1/2 flex items-center justify-center">
          <Lottie
            elementId="slider"
            data={require('../../../resources/lottie-slider-data.json')}
            className="absolute"
            style={{ top: -100 }}
          />
        </div>
      </div>
    </Container>
  );
}

export default Description;
